<template>
    <div class="page f-c">
        <div class="f-c-c">
            <h1>页面走丢了</h1>
            <h2>404</h2>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {

            }
        }
    }
</script>
<style lang="scss" scoped>
.page{
    height: 100vh;
    width: 100vw;
}
</style>